import faoShwarzenegger from './assets/images/fao-schwarzenegger.svg'
import doeEyedDear from './assets/images/doe-eyed-dear.svg'
import hipsterHumbugger from './assets/images/hipster-humbugger.svg'
import roaminHoliday from './assets/images/roamin-holiday.svg'
import noFilterNoelle from './assets/images/no-filter-noelle.svg'
import johnCandycane from './assets/images/john-candycane.svg'
import loveyTurtleDovey from './assets/images/lovey-turtle-dovey.svg'
import majorJoy from './assets/images/major-joy.svg'

const data = {
  "characters" : 
	[
		{
			"id" : "doe-eyed-dear",
			"archetype": "innocent",
			"name" : "Doe-eyed Dear",
			"words" : ["sweet", "wholesome", "idealistic", "trusting", "loyal" ],
			"quote" : "peace, love & sugar cookies",
			"illustration": doeEyedDear,
			"description": (
				<>
					<p>
						It's the most wonderful time of year that brings out your sweet and optimistic side. You embody the doe-eyed wonder of a newborn baby or the bliss of a golden retriever blitzing through a field of new-fallen snow. Everything appears to be happy and good in the world.
					</p>
					<p>
						Holidays inspire you to get out to stop and smell the snow angels, smile at strangers, and give back generously to your community. Slow to judge and quick to forgive, even the grouchiest holiday shoppers can't diminish your toasty disposition.
					</p>
					<p>
						While you sometimes reminisce for the simpler times of holidays past—before NFTs, rigged elections, and Kris Kringle deep fakes—you wouldn't trade a single thing for the good life you enjoy today, binge-watching Disney+ and enjoying hot chocolate with vegan marshmallows and loved ones.
					</p>
				</>
			),
			"motivation" : "You connect others during the holiday, it's what makes you, you. When you notice someone feeling left out, you step in with an introduction",
			"nice": (
				<ul>
					<li>
						Enthusiastically sings carols to brighten the day of disgruntled TSA agents
					</li>
					<li>
						Waits to open their gifts until after everyone else is done to absorb every second of glee and wonder
					</li>
					<li>
						Forgives and forgets past wrongdoings (even if they supported France in the World Cup)
					</li>
				</ul>
			),
			"naughty": (
				<ul>
					<li>
						Prolonged periods of dwelling underneath the mistletoe
					</li>
					<li>
						Lies about your age in order to sit on Santa's lap
					</li>
					<li>
						Interrupts holiday parades to say hello to the family you see across the street
					</li>
				</ul>
			)
		},
		{
			"id" : "lovey-turtle-dovey",
			"archetype": "lover",
			"name" : "Lovey Turtle Dovey",
			"words" : ["giddy", "passionate", "indulgent", "affectionate", "effusive" ],
			"quote" : "I Only Have Eyes for Yule",
			"illustration": loveyTurtleDovey,
			"description": (
				<>
					<p>
						To call you over-enthusiastic would be an understatement. This time of year lights your spirit brighter than the Christmas tree at 30 Rockefeller Center. That's because the holidays bring together the three things you love most in life: people, people, and people!
					</p>
					<p>
						You have a knack for making those around you feel loved by way of thoughtful gifts and the avalanche of affection that comes with every hello and goodbye. Spoiling and doting on others is your thing, sometimes to the point of smothering people with coma-inducing meals, conversation, and libations.
					</p>
					<p>
						Your seasonal decor looks as though a Thomas Kinkade painting exploded all over your living room, hallways, and home office. With off-the-chart levels of Public Displays of Holiday Affection (PDHA), you make even your most frosty friends and family members hooked on the holidays and ambassadors of merriment.
					</p>
				</>
			),
			"motivation" : "You connect others during the holiday, it's what makes you, you. When you notice someone feeling left out, you step in with an introduction",
			"nice": (
				<ul>
					<li>
						Creates commemorative cross-stitch portraits of every family member
					</li>
					<li>
						Brings a dozen spare white elephant gifts so no one is left out
					</li>
					<li>
						Decorates the elderly neighbor's yard… despite the “Keep Off My Lawn” sign
					</li>
				</ul>
			),
			"naughty": (
				<ul>
					<li>
						Has a hidden copy of “50 Shades of Snow” tucked away in the nightstand
					</li>
					<li>
						Adopts 25 stray kittens every December and calls it a “cat-vent calendar”
					</li>
					<li>
						Won't let anyone leave the table until they had a third helping of turkey and stuffing
					</li>
				</ul>
			)
		},
		{
			"id" : "major-joy",
			"archetype": "ruler",
			"name" : "Major Joy",
			"words" : ["joyous", "responsible", "considerate", "meticulous", "authoritative" ],
			"quote" : "Putting the Type-A in Holiday",
			"illustration": majorJoy,
			"description": (
				<>
					<p>
						You're a serious joy-maker during the holiday season. And we mean serious. Planning the ultimate holiday gathering is not something you take lightly. It takes meticulous planning, checklists, carefully curated seating plans, playlists, and your signature color-coded binder.
					</p>
					<p>
						When the big day arrives, you make it look so effortless: stealthily filling empty glasses, dropping cluster bombs of pithy conversation to light up the room, deftly hiding the bad wine someone brought, guarding against the offensive drunk uncle, and so much more.
					</p>
					<p>
						When you are in Major Joy mode, everyone is safe, cared for, and reveling in every minute of joy—whether they like it or not!
					</p>
				</>
			),
			"motivation" : "You connect others during the holiday, it's what makes you, you. When you notice someone feeling left out, you step in with an introduction",
			"nice": (
				<ul>
					<li>
						Appeases every dietary, religious, and taste-based requirement, from bar to buffet
					</li>
					<li>
						Wrangles the entire office to make an epic donation to the local shelter
					</li>
					<li>
						Gladly takes on all the planning duties so guests can kick back and relax
					</li>
				</ul>
			),
			"naughty": (
				<ul>
					<li>
						Rewraps other peoples' gifts under the tree so they match the motif
					</li>
					<li>
						Seats your conservative and liberal relatives next to each other just to see what happens
					</li>
					<li>
						Becomes “Hostzilla” and insists everyone play your elaborate holiday games
					</li>
				</ul>
			)
		},
		{
			"id" : "fao-schwarzenegger",
			"archetype": "hero",
			"name" : "FAO Schwarzenegger",
			"quote" : "Here I come to Sleigh the Day",
			"illustration": faoShwarzenegger,
			"description": (
				<>
					<p>
						Holidays aren't just any time of year, they're THE time of year for maximizing cheer and forging memories that will live on forever in your Instagram feed. 
					</p>
					
					<p>
						When celebrating your family and friends, no wish list is too long, no escalator too tall, and no Starbucks order is too complicated. You cheerfully dash through the snow in your EV-powered sleigh while rocking out to “Eye of the Tiger,” “Frosty the Snowman,” and “Let it Go.” Your Waze app is loaded with a retail hit list, maximizing BOGO sales, and minimizing traffic jams.
					</p>
					
					<p>
						No plant-based/paleo/ovo/flexitarian requests can make you skip a beat this year; it's already handled with care. But small wins aside, the real reward is having optimized time to amaze your loved ones and show how much you care for them.
					</p>
				</>
			),
			"words" : ["courageous", "ambitious", "persistent", "skilled", "caring"],
			"motivation" : "You connect others during the holiday, it's what makes you, you. When you notice someone feeling left out, you step in with an introduction",
			"nice": (
				<ul>
					<li>
						Uses AI chatbots to craft your personalized holiday greetings
					</li>
					<li>
						Creates a secret scorecard counting oohs and aahs received by other gift-givers
					</li>
					<li>
						Rigs the Secret Santa pool to ensure winning participant pairings
					</li>
				</ul>
			),
			"naughty": (
				<ul>
					<li>
						Uses AI chatbots to craft your personalized holiday greetings
					</li>
					<li>
						Creates a secret scorecard counting oohs and aahs received by other gift-givers
					</li>
					<li>
						Rigs the Secret Santa pool to ensure winning participant pairings
					</li>
				</ul>
			)
		},
		{
			"id" : "john-candycane",
			"archetype": "jester",
			"name" : "John Candycane",
			"words" : ["playful", "fun-loving", "precocious", "energetic", "inclusive" ],
			"quote" : "stoking Cheer for All to Hear",
			"illustration": johnCandycane,
			"description": (
				<>
					<p>
						You're festive and freewheeling, a natural holiday ringleader whose gift is getting everybody to join in on the fun and have a sweet time together. Making spirits bright is what gets you up in the morning.
					</p>
					<p>
						Your exuberant antics may take on many forms throughout the season—from caroling with strangers on the subway to leaving cheeky gifts on your coworkers' desks that make them ROTFL. At holiday shindigs, you're the pulse of the party reindeer games.
					</p>
					<p>
						Your glee and lightheartedness bring comfort and joy to those around you. You remind people how good it feels to connect with our inner child and bring more play, joy, and light into our adult lives.
					</p>
				</>
			),
			"motivation" : "You connect others during the holiday, it's what makes you, you. When you notice someone feeling left out, you step in with an introduction",
			"nice": (
				<ul>
					<li>
						Brings random guests to dinner you just met at the bus station
					</li>
					<li>
						Gamifies doing the dishes to trick the kids into helping out
					</li>
					<li>
						Plays the fool to break the ice and makes everyone feel more comfortable
					</li>
				</ul>
			),
			"naughty": (
				<ul>
					<li>
						Gifts socks in a PS5 box
					</li>
					<li>
						Amps the kids on candy and cocoa until they turn into nasty monsters
					</li>
					<li>
						Embarrasses date by telling them the wrong dress code for the office holiday party
					</li>
				</ul>
			)
		},
		{
			"id" : "hipster-humbugger",
			"archetype": "creator",
			"name" : "Hipster Humbugger",
			"words" : ["artsy", "intentional", "curated", "aloof", "opinionated"],
			"quote" : "Too Cool for Yule",
			"illustration": hipsterHumbugger,
			"description": (
				<>
					<p>
						While neighbors deck their yards with inflatable snowmen and oversized candy canes, your wise-cracking inner cynic makes its annual appearance. The garish green and red aesthetic, pumpkin spice mochas, and overtly hopeful holiday playlists are an assault on your senses. For you, holiday cliches are to be avoided like a niece or nephew with pink eye.
					</p>
					<p>
						If you are going to partake in the holidays, you elevate the abysmal into something artisanal—like free-range charcuterie boards and matcha-infused hot toddies. You scoff at traditional 'store-bought' holiday trappings, but you are totally down for an avant-garde Advent calendar or a wild-foraged, wreath-making workshop.
					</p>
					<p>
						Others may miscast you as a Scrooge or Jack Frost, but we see through your 'too cool for yule' veneer; in your heart of hearts, you long for the holidays as an opportunity to meaningfully connect with the people that matter most.
					</p>
				</>
			),
			"motivation" : "You connect others during the holiday, it's what makes you, you. When you notice someone feeling left out, you step in with an introduction",
			"nice": (
				<ul>
					<li>
						Makes a lavish holiday donation but keeps it on the down-low
					</li>
					<li>
						Encourages kids to express themselves through Japanese napkin art to help set the table
					</li>
					<li>
						Spikes eggnog with a $900 bottle of Pappy Van Winkle's 50-year scotch
					</li>
				</ul>
			),
			"naughty": (
				<ul>
					<li>
						Won't stop boasting that their sustainable u-cut Christmas tree smells better than yours
					</li>
					<li>
						Only 'mouths' the words to Christmas carols
					</li>
					<li>
						Brings signature side dish that is actually from the freezer case at Safeway
					</li>
				</ul>
			)
		},
		{
			"id" : "roamin-holiday",
			"archetype": "explorer",
			"name" : "Roamin' Holiday",
			"words" : ["adventurous", "offbeat", "experimental", "elusive", "non-conformist"],
			"quote" : "Don't White Picket Fence Me In",
			"illustration": roaminHoliday,
			"description": (
				<>
					<p>
						You're a wanderer at heart whose babysitter, the Travel Channel, got you hooked on otherworldly holiday customs from the other side of the globe before you could even pronounce Chanukah.
					</p>
					<p>
						Ever since moving out of your parent's basement, you've doubled down on your desire to expand holiday traditions and make the season more colorful. Inspired by your wanderlust made possible by Trader Joe's, your family has come to expect the unexpected when it comes to the offbeat recipes and whimsical treats you bring home for the holidays.
					</p>
					<p>
						From your plant-based, nootropic-infused, turmeric eggnog cocktails to your rosewater-infused Persian Love Cake, you remind friends and family that taking time to try new things and see new perspectives is what brings our entire world closer together.
					</p>
				</>
			),
			"motivation" : "You connect others during the holiday, it's what makes you, you. When you notice someone feeling left out, you step in with an introduction",
			"nice": (
				<ul>
					<li>
						Buys carbon offsets for their flight home
					</li>
					<li>
						Showers people with thoughtful trinkets from their treks
					</li>
					<li>
						Livens up the party with holiday classics on the digeridoo
					</li>
				</ul>
			),
			"naughty": (
				<ul>
					<li>
						Never sets a departure date and makes others' couches their home
					</li>
					<li>
						Borrows the car to run errands, returns it empty 12 hours later
					</li>
					<li>
						Overshares tales from Burning Man and that time you studied abroad on a cruise ship
					</li>
				</ul>
			)
		},
		{
			"id" : "no-filter-noelle",
			"archetype": "outlaw",
			"name" : "No Filter Noelle",
			"words" : ["rebellious", "risk-taking", "gutsy", "street smart", "unapologetic"],
			"quote" : "To boldly go where no relative has gone before",
			"illustration": noFilterNoelle,
			"description": (
				<>
					<p>
						You're a rebel without a Claus. While others try notching up last-minute credits on the nice side of the ledger, you are true to your naughty self during the holidays.
					</p>
					<p>
						Your goal is to liberate people from the stodgy seasonal rituals and traditions, shake them from their overly constructed social media storylines, and encourage people to feel all their holiday feels authentically and unencumbered.
					</p>
					<p>
						You serve up a spicy mix of joy, jeer, and Jell-O shots. Unafraid to speak your mind, you'll call an ugly sweater uuuuggly and air dirty laundry at the dinner table. Your rabble-rousing commentary can be seen as confrontational to some and comforting to others, yet always has a way of bringing people together through candor and honesty.
					</p>
				</>
			),
			"motivation" : "You connect others during the holiday, it's what makes you, you. When you notice someone feeling left out, you step in with an introduction",
			"nice": (
				<ul>
					<li>
						Takes the grandparents on joy rides
					</li>
					<li>
						Stands up for fellow misfits and outcasts in the family
					</li>
					<li>
						Creates diversions to save the teens trapped in a conversation with boring Boomers
					</li>
				</ul>
			),
			"naughty": (
				<ul>
					<li>
						Shows kids the dark art of sneaking peaks at their presents
					</li>
					<li>
						Brings pie with a missing slice
					</li>
					<li>
						Breaks the heirloom ornament and blames it on the baby
					</li>
				</ul>
			)
		},
	],
}

export default data