// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBAF__DSy3JgjKmeZgTqXWbH1-Gr9T60Ho",
  authDomain: "holiday-2022-1fd81.firebaseapp.com",
  databaseURL: "https://holiday-2022-1fd81-default-rtdb.firebaseio.com",
  projectId: "holiday-2022-1fd81",
  storageBucket: "holiday-2022-1fd81.appspot.com",
  messagingSenderId: "936535388857",
  appId: "1:936535388857:web:9c060e1c3a0e9cd128a168",
  measurementId: "G-4NLHWZSRHM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const database = getDatabase(app);