import styled from "styled-components";

export const Step = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 100px;
  opacity: 0;
  visibility: hidden;
  padding: 20px 0;
  max-height: calc(var(--vh) * 100);
  padding-bottom: 40px;
  overflow: auto;
  width: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;

  @media (max-width: 1200px) {
    min-height: calc(var(--vh) * 100);
    padding: 0;
  }

  @media (max-width: 1024px) {
    left: 0;
    right: 0;
    gap: 50px;
  }

  @media (max-height: 800px) {
    gap: 50px;
  }

  &.active {
    animation-name: fadeIn;
    animation-duration: 100ms;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    z-index: 11;

    .header {
      h1, p {
        animation-name: headerFadeIn;
        animation-duration: 400ms;
        animation-timing-function: ease-in;
        animation-fill-mode: forwards;
      }
    }

    .cardlist .card {
      animation-name: cardFadeIn;
      animation-duration: 375ms; // update
      animation-timing-function: ease-in;
      animation-fill-mode: forwards; 

      > div:after {
        animation-name: gradientFadeOut;
        animation-duration: 400ms;
        animation-timing-function: ease-in;
        animation-fill-mode: forwards; 
      }

      @keyframes gradientFadeOut {
        from {
          top: -150px;
        }
        to {
          top: 100%;
        }
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      visibility: hidden; 
    }

    100% {
      opacity: 1;
      visibility: visible; 
    }
  }

  @keyframes headerFadeIn {
    0% {
      opacity: 0;
      top: 10px;
    }

    100% {
      opacity: 1;
      top: 0;
    }
  }

  @keyframes cardFadeIn {
    0% {
      opacity: 0;
      transform: scale(1.1);
    }

    35% {
      transform: scale(0.97);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    color: #fff;

    h1 {
      font-size: 36px;
      line-height: 42px;
      font-weight: 400;
      position: relative;
      top: 6px;
      opacity: 0;
      text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

      @media (max-width: 1440px) {
        font-size: 30px;
        line-height: 34px;
      }

      @media (max-width: 1200px) {
        margin-top: 40px;
      }
    }

    p {
      font-size: 23px;
      line-height: 28px;
      font-family: serif;
      max-width: 450px;
      margin: 0 auto;
      text-align: center;
      font-family: 'adele', serif;
      font-style: italic;
      position: relative;
      top: 6px;
      opacity: 0;
      animation-delay: 100ms;
      text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      padding: 0 15px;

      @media (max-width: 1440px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  .cardlist {
    display: grid;
    grid-template-columns: repeat(4, minmax(224px, 1fr));
    gap: 60px;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 40px;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;

    @media (max-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
      width: 100%;
      max-width: 600px;
    }

    @media (max-width: 500px) {
      gap: 40px;
      padding: 0 30px;
    }

    &.half {
      grid-template-columns: repeat(2, minmax(224px, 285px));
      justify-content: center;

      @media (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
        width: 100%;
        max-width: 600px;
      }
    }
  
    input {
      display: none;
    }

    > div {
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      transform-style: preserve-3d;
      -webkit-transform-style: preserve-3d;
      &:nth-child(1) .card {
        animation-delay: 100ms;

        > div:after {
          animation-delay: 100ms;
        }
      }

      &:nth-child(2) .card {
        animation-delay: 250ms;

        > div:after {
          animation-delay: 250ms;
        }
      }

      &:nth-child(3) .card {
        animation-delay: 400ms;

        > div:after {
          animation-delay: 400ms;
        }
      }

      &:nth-child(4) .card {
        animation-delay: 550ms;

        > div:after {
          animation-delay: 550ms;
        }
      }
    }
    
    .card {
      position: relative;
      display: block;
      opacity: 0;
      transform: scale(1.1);
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      transform-style: preserve-3d;
      -webkit-transform-style: preserve-3d;

      div {
        background-color: #006240;
        border-radius: 5px;
        display: flex;
        padding: 100px 20px;
        color: #fff;
        flex-direction: column;
        align-items: center;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        transition: transform 250ms cubic-bezier(0.620, 1.060, 0.595, 1.650); /* custom */
        transition-timing-function: cubic-bezier(0.620, 1.060, 0.595, 1.650); /* custom */

        @media (max-width: 1200px) {
          padding: 70px 14px;
        }

        @media (max-width: 500px) { 
          padding: 60px 8px;
        }

        &:after {
          display: block;
          content: '';
          position: absolute;
          top: -150px;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgb(0,98,64);
          background: linear-gradient(0deg, rgba(0,98,64,1) 44%, rgba(41,41,41,0) 100%);
          transition: 0.3s;

          @media (max-width: 1024px) {
            display: none;
          }
        }
      }

      @media (min-width: 1024px) {
        &:hover {
          color: #D4E8C1;
          
          div, &:before {
            transform: scale(0.97);
          }
          
          &:before {
            background: #D4E8C1;
          }
        }

        @keyframes cardHover {
          0% {
            transform: scale(1);
          }

          50% {
            transform: scale(0.95);
          }

          100% {
            transform: scale(0.97);
          }
        }
      }

      &.active {
        div {
          background-color: #7A6A45;
          color: #fff;
          transform: scale(1.05) !important;
          transition: 0.3s;
        }

        &:before {
          background-color: #fff;
          transform: scale(1.05) !important;
          transition: 0.3s;
        }
      }

      &:before {
        background: #fff;
        position: absolute;
        display: block;
        content: '';
        left: -10px;
        z-index: -1;
        border-radius: 5px;
        right: -10px;
        top: -10px;
        bottom: -10px;
        transition: transform 250ms cubic-bezier(0.620, 1.060, 0.595, 1.650); /* custom */
        transition-timing-function: cubic-bezier(0.620, 1.060, 0.595, 1.650); /* custom */
      }

      p {
        font-weight: 400;
        font-size: 28px;
        line-height: 54px;

        @media (max-height: 1000px) {
          font-size: 24px;
          line-height: 40px;
        }

        @media (max-width: 1440px) {
          font-size: 24px;
          line-height: 40px;
        }

        @media (max-width: 500px) {
          font-size: 18px;
          line-height: 26px;
        }
      }
    }
  }

  button {
    background: #F2424E;
    border-radius: 30px;
    cursor: pointer;
    padding: 12px  10px;
    width: 220px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    border: 0;
    margin: 0 auto;
    display: block;
    font-size: 28px;
    line-height: 28px;
    animation-fill-mode: forwards;
    animation-duration: 400ms;
    animation-timing-function: all 0.3s cubic-bezier(0.7, 0, 0.84, 0);
    animation-delay: 0;

    
    font-family: "mono45-headline", sans-serif;
    font-weight: 400;
    transition: 0.3s;

    @media (min-width: 1024px) {
      &:not(:disabled) {
        animation-name: buttonTransition;
      }
      opacity: 0;
      transform: scale(1.4);
    }

    @media (max-width: 1440px) {
      font-size: 24px;
      line-height: 24px;
    }

    @media (max-width: 1024px) {
      margin-bottom: 80px;
    }

    &:disabled {
      opacity: 0 !important;
      visibility: hidden !important;
      cursor: default;
    }
    
    @media (min-width: 1024px) {
      &:hover {
        background-color: #A6272D;
      }
    }
  }

  @keyframes buttonTransition {
    0% {
      transform: scale(1.4);
      opacity: 0;
    }

    50% {
      transform: scale(0.9);
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`