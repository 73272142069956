import { useState } from 'react';
import { useParams } from 'react-router-dom';

import CharacterDetails from '../components/characterDetails/CharacterDetails'
import CharacterActions from '../components/characterActions/CharacterActions.js'
import data from '../data'

const Character = ({ handlePlayAction }) => {
  const params = useParams();
  const [section, setSection] = useState('details')
  
  const character = data.characters.find(character => character.id === params.id)

  if (!character) {
    return null
  }

  const handleClick = (section) => {
    handlePlayAction()
    setSection(section)
  }

  return (
    <>
      <CharacterDetails character={character} setSection={handleClick} className={section === 'details' ? 'active' : ''} />
      <CharacterActions className={section !== 'details' ? 'active' : ''} />
    </>
  );
}

export default Character;
