import styled from "styled-components";

export const Wrapper = styled.div`
  background: red;
`

export const Character = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  max-width: 1270px;
  margin: 0 auto;
  max-height: calc(var(--vh) * 100);
  min-height: calc(var(--vh) * 100);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 0;
  
  @media (max-height: 800px) {
    justify-content: flex-start;
  }
 
  @media (max-width: 1024px) {
    padding-bottom: 100px;
    justify-content: flex-start;
  }

  .card {
    perspective: 3000px;
    
    .flip {
      transform-style: preserve-3d;
      animation-name: flipSpin;
      animation-duration: 1.3s;
      animation-timing-function: cubic-bezier(0.450, 0.965, 0.250, 0.920);
      animation-fill-mode: forwards;
      animation-delay: 800ms;
    }

    @keyframes flipSpin {
      from {
        transform: rotateY( 0deg );
      }

      to {
        transform: rotateY( 540deg );
      }
    }
  }

  &.active {
    animation-fill-mode: forwards;
    animation-name: fadeIn;
    animation-duration: 300ms;
    animation-timing-function: all 0.3s cubic-bezier(0.7, 0, 0.84, 0);
    animation-delay: 550ms;

    .inner-wrapper {
      .step, .see-more {
        opacity: 1;
      }
    }

    .content {
      .left {
        h1, img {
          animation-fill-mode: forwards;
          animation-name: copyFadeIn;
          animation-duration: 400ms;
          animation-timing-function: all 0.3s cubic-bezier(0.7, 0, 0.84, 0);
          animation-delay: 500ms;
        }
      }

      .right {
        h2, p, ul li {
          animation-fill-mode: forwards;
          animation-name: copyFadeIn;
          animation-duration: 400ms;
          animation-timing-function: all 0.3s cubic-bezier(0.7, 0, 0.84, 0);
          animation-delay: 400ms;
        }

        p {
          animation-delay: 700ms;
        }
      }
    }    
  }

  @keyframes copyFadeIn {
    from {
      opacity: 0;
      top: 10px;
    }

    to {
      opacity: 1;
      top: 0;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      visibility: hidden;
    }

    to {
      opacity: 1;
      visibility: visible;
    }
  }

  .front {
    position: absolute;
    background-color: #006240;
    border-radius: 4px;
    outline: 20px solid #fff;
    width: calc(100% - 80px);
    margin: 0 auto;
    margin-top: 20px;
    top: -20px;
    bottom: 0;
    right: 0;
    left: 0;
    /* z-index: 999; */
    backface-visibility: hidden;
  }

  .inner-wrapper {
    background-color: #006240;
    backface-visibility: hidden;
    border-radius: 4px;
    outline: 20px solid #fff;
    color: #fff;
    width: calc(100% - 80px);
    margin: 0 auto;
    margin-top: 20px;
    transform: rotateY( 180deg );

    @media (max-width: 1024px) {
      outline: 10px solid #fff;
      width: calc(100% - 50px);
    }

    .step {
      height: 70px;
      align-items: center;
      display: flex;
      justify-content: flex-end;
      padding-right: 40px;
      font-family: "adelle",serif;
      font-style: italic;
      font-weight: 400;
      font-size: 20px;
      opacity: 0;
      transition: 400ms;
      transition-delay: 300ms;

      @media (max-width: 1024px) {
        display: none;
      }
    }

    .content {
      display: flex;

      @media (max-width: 1024px) {
        flex-direction: column;
      }

      .left {
        position: relative;
        padding: 0 70px 0 90px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 40px;
        flex: 1;
        max-width: 47%;

        @media (max-width: 1024px) {
          margin-right: 0;
          padding: 30px 45px 20px 45px;
          max-width: 100%;
          gap: 60px;
        }

        h1 {
          font-size: 72px;
          line-height: 105%;
          font-weight: 400;
          opacity: 0;
          position: relative;
          top: 10px;

          @media (max-height: 1000px) {
            font-size: 60px;
          }

          @media (max-width: 1440px) {
            font-size: 50px;
          }

          @media (max-width: 1024px) {
            font-size: 42px;
            line-height: 42px;
            text-align: center;
          }
        }

        img {
          display: block;
          margin-left: -35px;
          margin-top: 20px;
          margin-bottom: -50px;
          opacity: 0;
          position: relative;
          width: calc(100% + 35px);

          @media (max-height: 1000px) {
            max-height: 420px;
          }

          @media (max-height: 750px) {
            max-height: 350px;
          }

          @media (max-width: 1024px) {
            margin: 0 auto;
            max-width: 100%;
          }
        } 

        &:after {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 2px;
          background-color: #D4BE68;
          display: block;
          content: '';

          @media (max-width: 1024px) {
            display: none;
          }
        }
      }

      .right {
        padding: 20px 80px 20px 75px;
        display: flex;
        flex-direction: column;
        flex: 1.2;

        @media (max-width: 1024px) {
          padding: 25px 37px;

          &.hide-mobile {
            display: none;
          }
        }

        &.mobile {
          display: none;

          @media (max-width: 1024px) {
            display: block;
          }

          h2 { 
            margin-bottom: 10px;
          }
        }

        @media (max-height: 1000px) {
          flex: 1.4;
        }

        h2 {
          font-size: 32px;
          line-height: 32px;
          color: #D5BE68;
          max-width: 400px;
          margin-bottom: 25px;
          opacity: 0;
          position: relative;
          top: 10px;

          @media (max-height: 1000px) {
            font-size: 26px;
            line-height: 28px;
          }

          @media (max-width: 1440px) {
            font-size: 24px;
            line-height: 26px;
          }

          @media (max-width: 1024px) {
            text-align: center;
            font-size: 22px;
            line-height: 22px;
            max-width: none;
            margin-bottom: 20px;
          }
        }

        p, ul li {
          font-family: 'Adelle';
          font-style: italic;
          font-weight: 400;
          font-size: 20px;
          line-height: 140%;
          margin-bottom: 40px;
          opacity: 0;
          position: relative;
          top: 10px;

          @media (max-height: 1000px) {
            font-size: 18px;
          }

          @media (max-width: 1440px) {
            font-size: 16px;
            margin-bottom: 15px;
          }

          @media (max-width: 1024px) {
            font-size: 17px;
            line-height: 153%;
          }

          &:last-child {
            margin-bottom: 0;
            
            @media (max-width: 1024px) {
              margin-bottom: 10px;
            }
          }
        }

        p {
          @media (max-width: 1024px) {
            margin-bottom: 40px;
          }
        }

        ul {
          margin-left: 20px;
          list-style-type: disc;
          margin-bottom: 26px;

          li {
            margin-bottom: 7px;

            @media (max-width: 1024px) {
              font-size: 15px;
              line-height: 150%;  
            }
          }
        }
      }
    }

    .see-more {
      height: 50px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      padding-right: 40px;
      padding-top: 5px;
      font-family: "adelle",serif;
      font-style: italic;
      font-weight: 400;
      color: #DBCB8E;
      opacity: 0;

      @media (max-width: 1024px) {
        display: none;
      }

      span {
        cursor: pointer;
      }
    }
    
  }

  button {
    position: relative;
    position: relative;
    margin-top: 50px;
    
    @media (max-width: 1024px) {
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }
`