import * as S from './styles'
import Snowfall from 'react-snowfall'
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";

import background from '../../assets/images/background.jpg'
import midground1 from '../../assets/images/midground1.png'
import midground2 from '../../assets/images/midground2.png'
import foreground1 from '../../assets/images/foreground1.png'
import foreground2 from '../../assets/images/foreground2.png'
import foreground3 from '../../assets/images/foreground3.png'

function Wrapper({ children }) {
  // const snowflake1 = document.createElement('img')
  // snowflake1.src = '../../../public/logo192.png'
  
  // const images = [snowflake1]

  return (
    <MouseParallaxContainer globalFactorX={0.1} globalFactorY={0.1}>
      <S.Wrapper>
        <Snowfall
          style={{ zIndex: 5, filter: 'blur(2px)' }}
          snowflakeCount={183}
          radius={[0.5, 8.5]}
          wind={[-1, 1]}
          speed={[0, 2]}
          color={'#fff'}
          // images={images}
        />
        
        <S.Background>
          <MouseParallaxChild factorX={0.3} factorY={0.1}>
            <img src={background} alt='Holiday Mailer'/>
          </MouseParallaxChild>
        </S.Background>

        <S.MidgroundTwo>
          <MouseParallaxChild factorX={0.7} factorY={0.3}>
            <img src={midground2} alt='Holiday Mailer'/>
          </MouseParallaxChild>
        </S.MidgroundTwo>
        
        <S.MidgroundOne>
          <MouseParallaxChild factorX={1.2} factorY={0.5}>
            <img src={midground1} alt='Holiday Mailer'/>
          </MouseParallaxChild>
        </S.MidgroundOne>
        
        <S.ForegroundThree>
          <MouseParallaxChild factorX={1.7} factorY={0.6}>
            <img src={foreground3} alt='Holiday Mailer'/>
          </MouseParallaxChild>
        </S.ForegroundThree>

        <S.ForegroundTwo>
          <MouseParallaxChild factorX={2} factorY={0.7}>
            <img src={foreground2} alt='Holiday Mailer'/>
          </MouseParallaxChild>
        </S.ForegroundTwo>

        <S.ForegroundOne>
          <MouseParallaxChild factorX={2.6} factorY={0.9}>
            <img src={foreground1} alt='Holiday Mailer'/>
          </MouseParallaxChild>
        </S.ForegroundOne>

        <S.Children>
          {children}
        </S.Children>
      </S.Wrapper>
    </MouseParallaxContainer>
  );
}
export default Wrapper;
