import styled from "styled-components";

export const Intro = styled.div`
  background-color: #006240;
  width: 100%;
  max-width: 550px;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-name: intro;
  animation-duration: 300ms;
  animation-timing-function: all 0.3s cubic-bezier(0.7, 0, 0.84, 0);
  animation-delay: 100ms;
  border-radius: 10px;
  position: relative;

  @media (max-width: 1024px) {
    max-width: 370px;
    width: calc(100% - 40px);
  }

  .inner-wrapper {
    padding: 60px 40px 50px 40px;
    max-height: calc(var(--vh) * 100);
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: 1024px) {
      padding: 40px 30px 30px 30px;
    }
  }

  &.disabled {
    opacity: 0;
    visibility: hidden;
    animation-name: fadeOut;
  }

  @keyframes fadeOut {
    
  }

  @keyframes intro {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .holiday {
    display: flex;
    align-items: flex-start;
    gap: 4px;

    img {
      animation-fill-mode: forwards;
      animation-duration: 900ms;
      animation-timing-function: all 0.3s cubic-bezier(0.7, 0, 0.84, 0);
      opacity: 0;
      transition: 0.5s;
    }

    img:nth-child(1) {
      transform: scale(1.2);
      animation-name: letterDecrease, opacityTransition;
      animation-delay: 100ms, 100ms;
    }
    
    img:nth-child(2) {
      transform: scale(0.8);
      animation-name: letterIncrease, opacityTransition;
      animation-delay: 100ms, 150ms;
    }

    img:nth-child(3) {
      transform: scale(1.2);
      animation-name: letterDecrease, opacityTransition;
      animation-delay: 100ms, 200ms;
    }

    img:nth-child(4) {
      transform: scale(0.8);
      animation-name: letterIncrease, opacityTransition;
      animation-delay: 100ms, 250ms;
    }

    img:nth-child(5) {
      transform: scale(1.2);
      animation-name: letterDecrease, opacityTransition;
      animation-delay: 100ms, 300ms;
    }

    img:nth-child(6) {
      transform: scale(0.8);
      animation-name: letterIncrease, opacityTransition;
      animation-delay: 100ms, 350ms;
    }

    img:nth-child(7) {
      transform: scale(1.2);
      margin-left: -10px;
      animation-name: letterDecrease, opacityTransition;
      animation-delay: 100ms, 400ms;
    }

    @keyframes opacityTransition {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    @keyframes letterDecrease {
      from {
        transform: scale(1.2);
      }

      to {
        transform: scale(1);
      }
    }

    @keyframes letterIncrease {
      from {
        transform: scale(0.8);
      }

      to {
        transform: scale(1);
      }
    }
  }

  h1, p, h2, span {
    animation-fill-mode: forwards;
    animation-duration: 800ms;
    animation-timing-function: all 0.3s cubic-bezier(0.7, 0, 0.84, 0);
    animation-delay: 200ms;
    position: relative;
  }

  img {
    max-width: 100%;

    &.bird {
      position: absolute;
      top: -70px;
      left: 0;

      @media (max-width: 1440px) {
        display: none;
      }
    }
  }

  h1, h2 {
    font-size: 28px;
    line-height: 28px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    margin-top: 50px;
    margin-bottom: 16px;
    top: 5px;
    animation-name: copyH1In;
    opacity: 0;
    font-family: "mono45-headline", sans-serif;
    font-weight: 700;

    @media (max-width: 1024px) {
      font-size: 22px;
      line-height: 22px;
      margin-top: 35px;
    }
  }

  h2 {
    font-size: 28px;
    color: #d4e8c1;
    margin-top: 0;
    margin-bottom: 20px;

    @media (max-width: 1024px) {
      font-size: 20px;
      line-height: 54px;
    }
  }

  p {
    color: #fff;
    text-align: center;
    animation-delay: 300ms;
    top: 20px;
    opacity: 0;
    animation-name: copyPIn;
    font-size: 21px;
    line-height: 135%;
    font-family: "adelle",serif;
    font-style: italic;
    font-weight: 400;

    @media (max-width: 1024px) {
      font-size: 19px;
      line-height: 140%;
    }
  }

  span {
    display: block;
    text-align: center;
    font-family: "adelle",serif;
    color: #fff;
    font-style: italic;
    font-weight: 400;
    margin: 30px 0 20px 0;
    animation-name: copyPIn;
    opacity: 0;
    top: 5px;
    animation-delay: 400ms;

    @media (max-width: 1024px) {
      font-size: 10px;
      line-height: 135%;
      margin-top: 16px;
    }
  }

  @keyframes copyH1In {
    from {
      top: 5px;
      opacity: 0;
    }

    to {
      top: 0;
      opacity: 1;
    }
  }

  @keyframes copyPIn {
    from {
      top: 5px;
      opacity: 0;
    }

    to {
      top: 0;
      opacity: 1;
    }
  }


  button {
    background: #F2424E;
    border-radius: 30px;
    cursor: pointer;
    padding: 12px  10px;
    width: 220px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    border: 0;
    margin: 0 auto;
    display: block;
    font-size: 28px;
    line-height: 28px;
    margin-top: 36px;
    animation-fill-mode: forwards;
    animation-duration: 700ms;
    animation-timing-function: all 0.3s cubic-bezier(0.7, 0, 0.84, 0);
    animation-delay: 500ms;
    animation-name: buttonTransition;
    transform: scale(1.4);
    opacity: 0;
    font-family: "mono45-headline", sans-serif;
    font-weight: 400;
    transition: 0.3s;

    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 24px;
    }

    &:hover {
      background-color: #A6272D;
    }
  }

  @keyframes buttonTransition {
    0% {
      transform: scale(1.4);
      opacity: 0;
    }

    50% {
      transform: scale(0.9);
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }


`


export const Children = styled.div`
  
`