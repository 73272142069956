import { useState } from 'react';

import Intro from '../components/intro/Intro'
import Step from '../components/step/Step'

import data from '../data'

const Home = ({ handlePlayBackground, handlePlayAction }) => {
  const [currentSection, setCurrentSection] = useState('intro')
  const [answers, setAnswers] = useState({
    step1: '',
    step2: '',
    step3: ''
  })  

  const cardsStep1 = data.characters.slice(0, 4);
  const cardsStep2 = data.characters.slice(4, 8);

  const cardsStep3 = [
    data.characters.find(character => character.id === answers.step1),
    data.characters.find(character => character.id === answers.step2)
  ]

  const handleUpdatedSection = (section) => {
    handlePlayAction()

    if (section === 'result') {
      return null
    }
    
    setCurrentSection(section)
  }

  console.log('answers', answers)

  return (
    <>
      <Intro 
        className={currentSection === 'intro' ? 'active' : 'disabled'} 
        updateSection={handleUpdatedSection}
        handlePlayBackground={handlePlayBackground}
      />

      <Step 
        title='pick your first card'
        description="Choose the card below that best describes your approach to the holiday season. Think shopping, baking, traveling, and partying."
        cards={cardsStep1}
        className={currentSection === 'step1' ? 'active' : 'disabled'} 
        updateSection={handleUpdatedSection}
        nextSection='step2'
        setAnswers={setAnswers}
        id='step1'
        answers={answers}
        key='step1'
      />

      <Step 
        title='pick your second card'
        description="Now, choose another card that best describes your approach to the holiday season. Think giving, entertaining, dreaming, and scheming."
        cards={cardsStep2}
        className={currentSection === 'step2' ? 'active' : 'disabled'} 
        updateSection={handleUpdatedSection}
        nextSection='step3'
        setAnswers={setAnswers}
        id='step2'
        answers={answers}
        key='step2'
      />

      <Step 
        title='choose one'
        description="You're almost there! Lastly, imagine yourself showing up to a holiday shindig. What are the personality traits you are most likely to bring to the party?"
        cards={cardsStep3}
        className={currentSection === 'step3' ? 'active' : 'disabled'} 
        updateSection={handleUpdatedSection}
        nextSection='result'
        answers={answers}
        setAnswers={setAnswers}
        id='step3'
        key='step3'
      />
    </>
  );
}

export default Home;
