import { useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import restartIcon from '../../assets/images/restart-icon.svg'
import rally from '../../assets/images/rally.svg'
import shareIcon from '../../assets/images/share.svg'
import title from '../../assets/images/actions-title.svg'
import * as S from './styles'

const baseURL = 'https://holidayarchetype.ziba.com'

const CharacterActions = ({ className }) => {
  const navigate = useNavigate()
  const [showCopyMessage, setShowCopyMessage] = useState()
  const timeoutRef = useRef()
  const params = useParams()

  const handleCopy = () => {
    console.log(timeoutRef.current)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    setShowCopyMessage(true)
    navigator.clipboard.writeText(`${baseURL}/character/${params.id}`)

    timeoutRef.current = setTimeout(() => {
      setShowCopyMessage(false)
    }, 3000)
  }

  return (
    <S.Wrapper className={className}>
      <img src={title} alt='Wishing you a Happy Holiday!' className='title' />
      <div className='bird-animation'></div>
      
      <div className='grid'>
        <div className='card restart' onClick={() => navigate('/')}>
          <div>
            <div className='box-img'>
              <img src={restartIcon} alt='Restart' />
            </div>

            <h2>
              Retake the quiz.
            </h2>
          </div>
        </div>

        <div className='card share' onClick={handleCopy}>
          <div>
            <div className='box-img'>
              <img src={shareIcon} alt='Share' />
            </div>

            <p>Click to copy</p>
            <h2>Post your archetype.</h2>
            <div className={`copied ${showCopyMessage ? 'show' : ''}`}>Copied!</div>
          </div>
        </div>

        <div className='card rally' onClick={() => window.open('https://rally.biz/2022holiday', '_blank', 'noreferrer')}>
          <div>
            <div className='box-img'>
              <img src={rally} alt='Rally' />
            </div>
            
            <h2>explore the creative process behind the quiz</h2>
          </div>
        </div>
      </div>
    </S.Wrapper>
  )
}

export default CharacterActions