import styled from "styled-components";

export const Button = styled.button`
  background: #F2424E;
  border-radius: 30px;
  cursor: pointer;
  padding: 12px  10px;
  width: 220px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  border: 0;
  margin: 0 auto;
  display: block;
  font-size: 28px;
  line-height: 28px;
  animation-fill-mode: forwards;
  animation-duration: 400ms;
  animation-timing-function: all 0.3s cubic-bezier(0.7, 0, 0.84, 0);
  animation-delay: 500ms;
  animation-name: buttonTransition;
  transform: scale(1.4);
  opacity: 0;
  font-family: "mono45-headline", sans-serif;
  font-weight: 400;
  transition: 0.3s;

  @media (max-width: 1440px) {
    font-size: 24px;
    line-height: 24px;
  }

  &:disabled {
    background-color: #E4E4E4;
    color: #A6272D;
    cursor: default;

    &:hover {
      background-color: #E4E4E4;
    }
  }
  
  &:hover {
    background-color: #A6272D;
  }

  @keyframes buttonTransition {
    0% {
      transform: scale(1.4);
      opacity: 0;
    }

    50% {
      transform: scale(0.9);
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`