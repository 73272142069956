import * as S from './styles'
import {
  useNavigate
} from "react-router-dom";
import { database } from '../../firebase'
import { ref, push } from 'firebase/database';

function Step({ 
  updateSection, 
  className, 
  title,
  description,
  cards,
  nextSection,
  id,
  answers,
  setAnswers
 }) {
  const navigate = useNavigate();

  const handleAnswers = (e, id) => {
    const { value } = e.target;

    setAnswers(old => ({
      ...old,
      [id]: value
    }))
  }

  const handleClick = async () => {
    updateSection(nextSection)

    const data = {
      result: answers?.step3
    }

    console.log('answers', answers)
    
    if (nextSection === 'result') {
      console.log('answers', answers)

      try {
        await push(ref(database, `/results`), {
          ...data
        }); 
      } catch (err) {
        console.log('error')
      }
      
      setAnswers({
        step1: '',
        step2: '',
        step3: ''
      })
      return navigate(`/character/${answers.step3}`)
    }
  }

  return (
    <S.Step className={className}>
      <div className='header'>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>

      <div className={`cardlist ${id === 'step3' ? 'half' : ''}`}>
        {cards.map((card, index) => {
          return !!card ? (
            <div key={`${id}-${index}`}>
              <label 
                className={`card ${!!answers[id] ? answers[id] === card.id ? 'active' : 'desactive' : ''}`} 
                htmlFor={`${id}-${card.id}`}
              >
                <div>
                  {/* {!!answers[id] && answers[id] !== card.id && (
                    <div className='card-desactive'></div>
                  )} */}
                  {card.words.map(item => (
                    <p key={item}>{item}</p>
                  ))}
                </div>
              </label>
              <input 
                value={card.id} 
                onClick={(e) => handleAnswers(e, id)} 
                type='radio' 
                id={`${id}-${card.id}`} 
                name={id} 
              />
            </div>
          ) : ''
        })}
      </div>

      <button disabled={!answers[id]} onClick={handleClick}>{id === 'step3' ? 'Reveal!' : 'Next!'}</button>

    </S.Step>
  );
}
export default Step;
