import { useState } from 'react'

import Button from '../button/Button'
import * as S from './styles'

const CharacterDetails = ({ character, setSection, className }) => {
  const [currentStep, setCurrentStep] = useState(1)

  return (
    <S.Character className={className}>
      <div className='card'>
        <div className='flip'>
          <div className='front'></div>
          <div className='inner-wrapper back'>
            <div className='step'>
              {currentStep}/2
            </div>
            <div className='content'>
              <div className='left'>
                <h1>{character.name}</h1>
                <img src={character.illustration} alt={character.name} />
              </div>
              {currentStep === 1 ? (
                <div className='right'>
                  <h2>
                    {character.quote}
                  </h2>

                  {character.description}
                </div>
              ) : (
                <div className='right hide-mobile center'>
                  <h2>
                    When you're nice
                  </h2>

                  {character.nice}

                  <h2>
                    When you're naughty
                  </h2>

                  {character.naughty}
                </div>
              )}

              <div className='right center mobile'>
                <h2>
                  When you're nice
                </h2>

                {character.nice}

                <h2>
                  When you're naughty
                </h2>

                {character.naughty}
              </div>
            </div>

            <div className='see-more'>
              {currentStep === 1 ? (
                <span onClick={() => setCurrentStep(2)}>see more...</span>
              ) : (
                <span onClick={() => setCurrentStep(1)}>back...</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <Button handleClick={() => setSection('actions')}>
        Next!
      </Button>
    </S.Character>
  )
}

export default CharacterDetails