import styled from "styled-components";

import bird from '../../assets/images/bird.gif'

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  max-height: calc(var(--vh) * 100);
  overflow-y: auto;
  top: 8vh;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 100px;

  @media (min-width: 2000px) {
    max-width: 1300px;
  }

  @media (max-width: 1440px) { 
    gap: 60px;
  }

  @media (max-width: 1024px) {
    padding-top: 60px;
    padding-bottom: 60px;
    top: 0;
  }

  .title {
    max-width: 830px;
    width: 100%;
    margin: 0 auto;
    display: block;
    opacity: 0;
    position: relative;
    z-index: 1;

    @media (max-height: 800px) {
      max-height: 150px;
    }

    @media (max-width: 1200px) {
      padding: 0 20px;
    }
  }

  .bird-animation {
    position: fixed;
    left: -30px;
    right: 0;
    top: -72px;
    width: 100%;
    display: none;
    z-index: 2;
    width: calc(100% + 70px);
    background-image: url(${bird});
    background-repeat: no-repeat;
    height: 100vh;

    @media (width: 1920px) and (height: 1080px) {
      display: block;
      top: -44px;
    }

    @media (width: 1440px) and (height: 796px) {
      display: block;
      background-size: cover;
      top: -68px;
    }

    @media (width: 1440px) and (height: 796px) {
      display: block;
      background-size: cover;
      top: -55px;
    }

    @media (width: 1536px) and (height: 864px) {
      display: block;
      background-size: cover;
      top: 7px;
    }

    @media (width: 1440px) and (height: 900px) {
      display: block;
      background-size: cover;
      top: 7px;
    }

    @media (width: 1366px) and (height: 657px) {
      display: block;
      background-size: cover;
      top: -62px;
    }

    @media (width: 1728px) and (height: 1117px) {
      display: block;
      background-size: cover;
      top: -39px;
      left: -100px;
      width: calc(100% + 100px);
    }

    @media (width: 1825px) and (height: 1011px) {
      display: block;
      top: -49px;
      left: -50px;
    }

    @media (width: 2095px) and (height: 1175px) {
      display: block;
      top: -87px;
      width: calc(100% + 63px);
      left: -20px;
      background-size: cover;
    }
  }

  h1 {
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    font-family: "adelle";
    color: #1D4A3C;
    max-width: 580px;
    margin: 0 auto;
    color: #fff;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

    @media (max-width: 1440px) {
      font-size: 20px;
      line-height: 24px;
      padding: 0 20px;
    }
  }

  &.active {
    animation-fill-mode: forwards;
    animation-name: fadeIn;
    animation-duration: 300ms;
    animation-timing-function: all 0.3s cubic-bezier(0.7, 0, 0.84, 0);
    animation-delay: 100ms;

    .title {
      animation-fill-mode: forwards;
      animation-name: fadeIn;
      animation-duration: 500ms;
      animation-timing-function: all 0.3s cubic-bezier(0.7, 0, 0.84, 0);
      animation-delay: 200ms;
    }

    .card {
      animation-name: cardFadeIn;
      animation-duration: 500ms;
      animation-timing-function: ease-in;
      animation-fill-mode: forwards; 
    }

    @keyframes cardFadeIn {
      0% {
        opacity: 0;
        transform: scale(1.1);
      }

      35% {
        transform: scale(0.97);
      }

      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
      visibility: visible;
    }
  }

  .grid {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 50px;
    position: relative;
    z-index: 5;

    @media (max-width: 1440px) {
      gap: 30px;
    }

    @media (max-width: 1024px) {
      padding: 0 40px;
      gap: 50px;
    }
    
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }

    .card {
      position: relative;
      display: block;
      opacity: 0;
      transform: scale(1.1);
      max-width: 240px;
      margin: 0 auto;
      width: 100%;

      &:nth-child(1) {
        animation-delay: 150ms;
      }

      &:nth-child(2) {
        animation-delay: 300ms;
      }

      &:nth-child(3) {
        animation-delay: 450ms;
      }

      > div {
        border-radius: 5px;
        padding: 50px 16px;
        color: #fff;
        flex-direction: column;
        align-items: center;
        position: relative;
        cursor: pointer;
        transition: 0.3s;
        justify-content: center;
        background-color: #006240;
        display: flex;
        height: 100%;
        transition: all 250ms cubic-bezier(0.620, 1.060, 0.595, 1.650); /* custom */
        transition-timing-function: cubic-bezier(0.620, 1.060, 0.595, 1.650); /* custom */

        .box-img {
          height: 108px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          @media (max-width: 1440px) {
            height: 100px;
          }

          @media (width: 1366px) and (height: 657px) {
            height: 50px;
          }

          img {
            max-height: 100%;
          }
        }
      }

      .copied {
        background-color: #F2424E;
        color: #fff;
        padding: 8px 30px;
        font-weight: 300;
        font-size: 24px;
        line-height: 42px;
        border-radius: 4px;
        position: absolute;
        bottom: -100px;
        opacity: 0;
        visibility: hidden;
        cursor: default;

        @media (max-width: 767px) {
          bottom: 20px;
          font-size: 16px;
          line-height: 28px;
          padding: 4px 20px;
        }

        &.show {
          animation-fill-mode: forwards;
          animation-duration: 300ms;
          animation-timing-function: all 0.3s cubic-bezier(0.7, 0, 0.84, 0);
          animation-name: buttonTransition;
        }

        @keyframes buttonTransition {
          0% {
            transform: scale(1.4);
            opacity: 0;
            visibility: hidden;
          }

          50% {
            transform: scale(0.9);
          }

          100% {
            transform: scale(1);
            opacity: 1;
            visibility: visible;
          }
        }
      }

      &.share {
        > div {
          background-color: #F2424E;

          @media (min-width: 1024px) {
            &:hover {
              background-color: #A6272D;
            }
          }
        }
      }

      &.restart {
        > div {
          background-color: #006240;

          @media (min-width: 1024px) {
            &:hover {
              background-color: #1D4A3C;
            }
          }
        }
      }

      &.rally {
        > div {
          background-color: #7A6A45;

          @media (min-width: 1024px) {
            &:hover {
              background-color: #7A6A45;
            }
          }

          img {
            width: 100%;
            max-width: 175px;
          }
        }
      }

      @media (min-width: 1024px) {
        &:hover {
          p {
            opacity: 1;
          }

          > div, &:before {
            transform: scale(0.98);
          }

          &:before {
            background: #E4E4E4;
          }
        }
      }

      &:before {
        background: #fff;
        position: absolute;
        display: block;
        content: '';
        left: -10px;
        z-index: -1;
        border-radius: 5px;
        right: -10px;
        top: -10px;
        bottom: -10px;
        transition: all 250ms cubic-bezier(0.620, 1.060, 0.595, 1.650); /* custom */
        transition-timing-function: cubic-bezier(0.620, 1.060, 0.595, 1.650); /* custom */
        box-shadow: 0px 0px 47.2314px rgba(0, 0, 0, 0.2);
      }

      h2 {
        font-size: 28px;
        line-height: 34px;
        text-align: center;
        margin-top: 60px;

        @media (max-width: 1440px) {
          font-size: 25px;
          line-height: 30px;
          margin-top: 50px;
        }

        @media (width: 1366px) and (height: 657px) {
          height: 90px;
          display: flex;
          align-items: center;
        }

        @media (max-width: 1024px) {
          font-size: 24px;
          line-height: 26px;
        }
      }

      p {
        font-weight: 400;
        position: absolute;
        top: 186px;
        font-size: 18px;
        line-height: 42px;
        opacity: 0;

        @media (max-width: 1440px) { 
          margin-top: 0;
          top: 176px;
        }
      }
    }
  }
`