import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import { useRef, useEffect } from "react"

import Wrapper from './components/wrapper/Wrapper'
import Home from './pages/Home'
import Character from './pages/Character'
import background from './assets/sounds/background.mp3'
import action from './assets/sounds/whoosh.mp3'

const App = () => {
  const backgroundAudioRef = useRef()
  const actionAudioRef = useRef()

  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + 'px');
    window.addEventListener('resize', () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', vh + 'px');
    })
  }, [])

  const handlePlayBackground = () => {
    backgroundAudioRef.current.volume = 0.2
    backgroundAudioRef.current.play()
  }

  const handlePlayAction = () => {
    actionAudioRef.current.pause()
    
    actionAudioRef.current.play()
    actionAudioRef.current.currentTime = 0
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home handlePlayBackground={handlePlayBackground} handlePlayAction={handlePlayAction} />,
    },
    {
      path: "/character/:id",
      element: <Character handlePlayAction={handlePlayAction} />,
    },
  ]);

  return (
    <Wrapper>
      <audio hidden loop ref={backgroundAudioRef}>
        <source src={background} type="audio/mp3" />
      </audio>

      <audio hidden ref={actionAudioRef} preload='auto'>
        <source src={action} type="audio/mp3" />
      </audio>

      <RouterProvider router={router} />
    </Wrapper>
  );
}

export default App;
