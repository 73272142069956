import styled from "styled-components";

import background from '../../assets/images/fixed-bg.png'

export const Wrapper = styled.div`
  height: calc(var(--vh) * 100);
  position: relative;
  
  @media (max-width: 1200px) {
    background: url(${background}) no-repeat center top;
    background-size: cover;
  }
`

export const Background = styled.div`
  position: fixed;
  left: -5vw;
  top: -3vh;
  bottom: -3vh;
  right: -5vw;
  
  @media (max-width: 1200px) {
    display: none;
  }

  img {
    object-fit: cover;
    height: 106vh;
    width: 110vw;
  }
`

export const MidgroundTwo = styled.div`
  position: fixed;
  left: -14vw;
  top: 5vh;

  @media (max-width: 1200px) {
    display: none;
  }

  @media (max-aspect-ratio: 4/3) {
    > div {
      position: fixed;
      bottom: -5vh;

      top: 0;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  img {
    width: 122vw;
  }
`

export const MidgroundOne = styled.div`
  position: fixed;
  left: 0vw;
  top: 2vh;

  @media (max-width: 1200px) {
    display: none;
  }

  @media (max-aspect-ratio: 4/3) {
    left: -9vw; 

    > div {
      position: fixed;
      bottom: -5vh;

      top: 0;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  img {
    width: 110vw;
  }
`

export const ForegroundThree = styled.div`
  position: fixed;
  left: -13vw;
  bottom: -30vh;
  right: 0vw;
  top: -5vh;

  @media (max-width: 1200px) {
    display: none;
  }

  @media (max-aspect-ratio: 4/3) {
    > div {
      position: fixed;
      bottom: -5vh;
      top: 0;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  img {
    width: 125vw;
  }
`

export const ForegroundTwo = styled.div`
  position: fixed;
  left: -13vw;
  bottom: -30vh;
  right: 0vw;
  top: -5vh;

  @media (max-width: 1200px) {
    display: none;
  }

  @media (max-aspect-ratio: 4/3) {
    > div {
      position: fixed;
      bottom: -5vh;
      top: 0;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  img {
    width: 125vw;
  }
`

export const ForegroundOne = styled.div`
  position: fixed;
  left: -15vw;
  bottom: -12vh;
  right: -15vw;
  z-index: 6;

  @media (max-width: 1200px) {
    display: none;
  }

  @media (max-aspect-ratio: 4/3) {
    > div {
      position: fixed;
      bottom: -5vh;
    }
  }

  img {
    object-fit: fill;
    width: 145vw;
    height: 45vh;

    @media (min-aspect-ratio: 16/9) {
      height: 30vh;
    }

    /* @media (max-aspect-ratio: 16/7) { 
      height: 30vh; 
    }

    @media (aspect-ratio: 20/13) { 
      height: 45vh; 
    }

    @media (max-aspect-ratio: 4/3) { 
      height: 20vh; 
    } */
  }
`

export const Children = styled.div`
  position: relative;
  z-index: 6;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`