import * as S from './styles'

import title from '../../assets/images/intro-title.svg'
import bird from '../../assets/images/bird.svg'
// import letterH from '../../assets/images/h.svg'
// import letterO from '../../assets/images/o.svg'
// import letterL from '../../assets/images/l.svg'
// import letterI from '../../assets/images/i.svg'
// import letterD from '../../assets/images/d.svg'
// import letterA from '../../assets/images/a.svg'
// import letterY from '../../assets/images/y.svg'

function Intro({ updateSection, className, handlePlayBackground }) {

  const handleClick = () => {
    handlePlayBackground()
    updateSection('step1')
  }

  return (
    <S.Intro className={className}>
      <img className='bird' src={bird} alt='Holiday' />
      <div className='inner-wrapper'>
        <h2>Ziba's world famous</h2>
        <div className='holiday'>
          <img src={title} alt='Holiday' />
          {/* <img src={letterO} alt='Holiday' />
          <img src={letterL} alt='Holiday' />
          <img src={letterI} alt='Holiday' />
          <img src={letterD} alt='Holiday' />
          <img src={letterA} alt='Holiday' />
          <img src={letterY} alt='Holiday' /> */}

        </div>
        <h1>discover your holiday alter ego!</h1>
        <p>
          Using quantum computing, blockchain technology and AI machine learning*, this quiz identifies how your personality transforms during the holiday season.
        </p>

        <span>*We're joking…</span>

        <button onClick={handleClick}>Let's go!</button>
      </div>
    </S.Intro>
  );
}
export default Intro;
